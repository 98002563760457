<script setup>
import {computed, ref, watch} from 'vue';
import VueTurnstile from 'vue-turnstile';
import {ThemeStore} from "@/Stores/index.js";

const props = defineProps({
    modelValue: [Object, String],
    form: {
        type: Object
    }
});

const emit = defineEmits(["update:modelValue"]);
const model = ref(props.modelValue);
const hasSiteKey = ref(!!import.meta.env.VITE_TURNSTILE_SITE_KEY);
const siteKey = import.meta.env.VITE_TURNSTILE_SITE_KEY;
const captcha = ref(null);

watch(model, model => {
    emit("update:modelValue", model);
});

watch(() => props.modelValue, newValue => {
        model.value = newValue;
    }
);

defineExpose({hasSiteKey});
</script>


<template>
    <div v-auto-animate>
        <VueTurnstile
            ref="captcha"
            v-if="hasSiteKey && !form?.processing"
            :language="$page.props.locale"
            :site-key="siteKey"
            v-model="model"
            :theme="ThemeStore.isDark() ? 'dark' : 'light'"
        />
    </div>
</template>

